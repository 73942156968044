<template>
    <div class="dashboard-logged mainWrapper">
    <section class="blockElement masterPips pb-3">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="d-flex justify-content-between flex-wrap mt-3 mt-md-5 mb-2">
                        <h3 class="medium mob-block f-28 darkblueColor mb-0">{{$t('compare_leaders.compareLeaders')}}</h3>
                        <a href="javascript:void(0)" class="button zulu_btn compare_btn rounded border-button ps-1 dark-text" @click="ShowTradersList()" :class="{'disabled' : store.customerDetail?.readOnly}">
                            <v-lazy-image src="assets/images/orange-plus.svg" :alt="$t('compare_leaders.text1')" :title="$t('compare_leaders.text1')" class="me-2" />
                            {{$t('compare_leaders.addLeaderstoCompare')}}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
        <section class="blockElement masterPips pb-3" v-if="Object.keys(store.compareTraderPerformance).length">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12 flex-row d-flex overflow-auto" v-if="store.compareTraderPerformance.result && store.compareTraderPerformance.result.length">
                        <table class="cataTable w-100" v-for="item,index in store.compareTraderPerformance.result" :key="index">
                            <tbody v-if="item.trader && Object.keys(item.trader).length > 0">
                                <tr>
                                    <td v-if="item.trader.profile && item.trader.profile.zuluAccountId">
                                        <div class="bordergS bg-white br-12 mt-2 p-0 p-3 text-center mx-auto position-relative">
                                            <div class="zlds-avatar menuitem">
                                                <span class="roundedImgeComare position-relative d-block my-3">
                                                    <v-lazy-image class="rounded-circle" :src="(!item.trader.profile.photoApproved) ? (item.trader.profile.customerAvatarUrl) ? item.trader.profile.customerAvatarUrl : static_vars.imagesURL+'webservices/Image.ashx?type=user&size=XL&id='+item.trader.profile.zuluAccountId+'&ignore=false' : static_vars.imagesURL+'webservices/Image.ashx?type=user&size=XL&id='+item.trader.profile.zuluAccountId+'&ignore=false'" :alt="item.trader?.profile?.name || ''" :title="item.trader?.profile?.name || ''" />
                                                </span>
                                            </div>
                                            <h5 class="semibold f-20 mb-0" v-if="item.trader.overallStats && item.trader.overallStats.zuluRank">
                                            <span>#{{item.trader.overallStats.zuluRank}} <a class="secondary medium" href="javascript:void(0)" v-if="item.trader.profile && item.trader.profile.name">{{item.trader.profile.name}}</a></span>
                                            <a class="position-absolute closeCompare" href="javascript:void(0)" @click="removeTrader(item)" v-if="tradersId.length > 1"><vue-feather class="close pointer" type="x-circle"></vue-feather></a></h5>
                                            <!-- <p class="f-14 gray mb-2">@drsrbrtsn22</p> -->
                                            <ul class="d-flex justify-content-center mb-0">
                                                <li class="me-2">
                                                    <span class="text-uppercase medium f-13 gray me-1">{{$t('compare_leaders.followers')}}</span>
                                                    <b class="f-13">{{item.trader.overallStats.followers || ''}}</b>
                                                </li>
                                                <!-- <li>
                                                    <span class="text-uppercase medium f-13 gray me-1">{{$t('compare_leaders.following')}}</span>
                                                    <b class="f-13">93</b>
                                                </li> -->
                                            </ul>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="py-3 w-100 bordergSB text-center">
                                        <span class="f-13 regular gray pb-1 d-block"> {{$t('compare_leaders.amountFollowing')}}</span>
                                        <h6 class="mb-0 darkblueColor f-15 medium">{{COMMON.getBaseCurrencySymbol()}}{{(parseFloat(item.trader.overallStats.amountFollowing || '').toFixed(2)).toLocaleString()}}</h6>
                                    </td>
                                </tr>

                                <tr>
                                    <td class="py-3 w-100 bordergSB text-center">
                                        <span class="f-13 regular gray pb-1 d-block"> {{$t('compare_leaders.profit')}}</span>
                                        <h6 class="mb-0 darkblueColor f-15 medium">{{(parseFloat(item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].profit || '').toFixed(2)).toLocaleString()}} {{$t('compare_leaders.pipsText')}}</h6>
                                    </td>
                                </tr>

                                <tr>
                                    <td class="py-3 w-100 bordergSB text-center">
                                        <span class="f-13 regular gray pb-1 d-block"> {{$t('compare_leaders.weeks')}}</span>
                                        <h6 class="mb-0 darkblueColor f-15 medium">{{parseFloat(item.trader.overallStats.weeks || '')}}</h6>
                                    </td>
                                </tr>

                                <tr class="">
                                    <td class="py-3 w-100 bordergSB text-center">
                                        <span class="f-13 regular gray pb-1 d-block"> {{$t('compare_leaders.investors')}}</span>
                                        <h6 class="mb-0 darkblueColor f-15 medium">{{parseFloat(item.trader.overallStats.followers || '')}}</h6>
                                    </td>
                                </tr>

                                <tr class="">
                                    <td class="py-3 w-100 bordergSB text-center">
                                        <span class="f-13 regular gray pb-1 d-block"> {{$t('compare_leaders.roi')}}</span>
                                       <h6 class="mb-0 darkblueColor f-15 medium">{{parseFloat(Math.round(item.trader.overallStats.rorBasedRoi || ''))}}%</h6>
                                    </td>
                                </tr>

                                <tr>
                                    <td class="py-3 w-100 bordergSB text-center">
                                        <span class="f-13 regular gray pb-1 d-block"> {{$t('compare_leaders.trades')}}</span>
                                        <h6 class="mb-0 darkblueColor f-15 medium">{{parseFloat(item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].trades || '')}}</h6>
                                    </td>
                                </tr>

                                <tr>
                                    <td class="py-3 w-100 bordergSB text-center">
                                        <span class="f-13 regular gray pb-1 d-block"> {{$t('compare_leaders.winningTrades')}}</span>
                                        <h6 class="mb-0 darkblueColor f-15 medium">{{parseFloat(Math.round(item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].winTrades || ''))}}%</h6>
                                    </td>
                                </tr>

                                <tr>
                                    <td class="py-3 w-100 bordergSB text-center">
                                        <span class="f-13 regular gray pb-1 d-block"> {{$t('compare_leaders.averagePipstrade')}}</span>
                                        <h6 class="mb-0 darkblueColor f-15 medium">{{parseFloat(item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].avgPipsPerTrade || '').toFixed(1)}}</h6>
                                    </td>
                                </tr>

                                <!-- <tr>
                                    <td class="py-3 w-100 bordergSB text-center">
                                        <span class="f-13 regular gray pb-1 d-block"> Avg Trade Time</span>
                                        <h6 class="mb-0 darkblueColor f-15 medium">{{tradeTime(item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].avgTradeSeconds) || 0}}</h6>
                                    </td>
                                </tr> -->

                                <tr>
                                    <td class="py-3 w-100 bordergSB text-center">
                                        <span class="f-13 regular gray pb-1 d-block">{{$t('compare_leaders.maximumDrawdown')}}</span>
                                        <h6 class="mb-0 darkblueColor f-15 medium">-{{parseFloat(item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].overallDrawDownMoneyPercent || '').toFixed(1)}}% (-{{(parseFloat(item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].overallDrawDownMoney || '').toFixed(1)).toLocaleString()}})</h6>
                                    </td>
                                </tr>

                                <tr>
                                    <td class="py-3 w-100 bordergSB text-center" v-if="item.trader.timeframeStats && Object.keys(item.trader.timeframeStats).length && item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]] && item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].maxOpenTrades">
                                        <span class="f-13 regular gray pb-1 d-block">{{$t('compare_leaders.maxOpenTrades')}}</span>
                                        <h6 class="mb-0 darkblueColor f-15 medium">{{item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].maxOpenTrades}}</h6>
                                    </td>
                                    <td class="py-3 w-100 bordergSB text-center" v-else>
                                            <span class="f-13 regular gray pb-1 d-block">{{$t('compare_leaders.maxOpenTrades')}}</span>
                                            <h6 class="mb-0 darkblueColor f-15 medium">{{$t('marketDetail.text24')}}</h6>
                                    </td>
                                </tr>

                                <tr>
                                    <td  class="py-3 w-100 bordergSB text-center" v-if="item.trader.overallStats && item.trader.overallStats.worstTrade">
                                        <span class="f-13 regular gray pb-1 d-block">{{$t('compare_leaders.worstTrade')}}</span>
                                        <h6 class="mb-0 darkblueColor f-15 medium">{{item.trader.overallStats.worstTrade || ''}} {{$t('compare_leaders.text2')}}</h6>
                                    </td>
                                    <td  class="py-3 w-100 bordergSB text-center" v-else>
                                        <span class="f-13 regular gray pb-1 d-block">{{$t('compare_leaders.worstTrade')}}</span>
                                        <h6 class="mb-0 darkblueColor f-15 medium">{{$t('marketDetail.text24')}} {{$t('compare_leaders.pips')}}</h6>
                                    </td>
                                </tr>

                                <tr>
                                    <td class="py-3 w-100 bordergSB text-center" v-if="item.trader.overallStats && item.trader.overallStats.bestTrade">
                                        <span class="f-13 regular gray pb-1 d-block">{{$t('compare_leaders.bestTrade')}}</span>
                                        <h6 class="mb-0 darkblueColor f-15 medium">{{item.trader.overallStats.bestTrade || ''}} {{$t('compare_leaders.pips')}}</h6>
                                    </td>
                                    <td class="py-3 w-100 bordergSB text-center" v-else>
                                        <span class="f-13 regular gray pb-1 d-block">{{$t('compare_leaders.bestTrade')}}</span>
                                        <h6 class="mb-0 darkblueColor f-15 medium">{{$t('marketDetail.text24')}} {{$t('compare_leaders.pips')}}</h6>
                                    </td>
                                </tr>

                                <!-- <tr>
                                    <td class="py-3 w-100 bordergSB text-center">
                                        <span class="f-13 regular gray pb-1 d-block">Copied by Real Investors</span>
                                        <h6 class="mb-0 darkblueColor f-15 medium">Yes</h6>
                                    </td>
                                </tr> -->

                                <tr>
                                    <td class="py-3 w-100 bordergSB text-center" v-if="item.trader.timeframeStats && Object.keys(item.trader.timeframeStats).length && item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]] && item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].nme">
                                        <span class="f-13 regular gray pb-1 d-block">{{$t('compare_leaders.necessaryMinimumEquity')}}</span>
                                        <h6 class="mb-0 darkblueColor f-15 medium">${{item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].nme.toLocaleString()}}</h6>
                                    </td>
                                    <td class="py-3 w-100 bordergSB text-center" v-else>
                                        <span class="f-13 regular gray pb-1 d-block">{{$t('compare_leaders.necessaryMinimumEquity')}}</span>
                                        <h6 class="mb-0 darkblueColor f-15 medium">{{$t('compare_leaders.text3')}}</h6>
                                    </td>
                                </tr>

                                <tr>
                                    <td class="py-3 w-100 bordergSB text-center">
                                        <span class="f-13 regular gray pb-1 d-block">{{$t('compare_leaders.viewed')}}</span>
                                        <h6 class="mb-0 darkblueColor f-15 medium">{{item.trader.profile && item.trader.profile.pageVisits ? item.trader.profile.pageVisits : 0}} {{$t('compare_leaders.times')}}</h6>
                                    </td>
                                </tr>
                                <!-- <tr>
                                    <td class="py-3 w-100 bordergSB text-center" colspan="2">
                                        <a href="javascript:void(0)" class="button fillBtn d-inline-flex zulu_btn btn-sm">Follow</a>
                                    </td>
                                </tr> -->
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </section>
    </div>
    <div class="modal" v-if="addTraders" :class="[{'d-block' : addTraders && store.searchTradersList.length}]">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
                <div class="modal-header bg-white border-0">
                    <h5 class="modal-title">{{$t('compare_leaders.addTraders')}}</h5>
                    <button type="button" class="btn-close" @click="addTraders = false"></button>
                </div>
                <div class="modal-body leaderToCompare">
                    <div class="col-12 mb-4">
                        <div class="form-search zuluInput w-100">
                            <input type="search" class="form-control" :placeholder="$t('compare_leaders.searchTrader')" v-model="serachTrader" @keyup="getTradersList()" />
                            <a href="javascript:void(0)" class="">
                                <i class="searchIcon searchIcon-auto" data-feather="search"></i>
                            </a>
                        </div>
                    </div>
                    <p class="medium" v-if="store.searchTradersList.length">{{$t('compare_leaders.clicktoAdd')}}</p>
                    <div class="d-flex justify-content-between align-items-center flex-wrap">
                        <div class="trader-list" v-for="item,index in store.searchTradersList.slice(0,4)" :key="index">
                            <div class="d-flex align-items-center justify-content-between">
                                <!-- <div class="me-2">
                                    <label class="cutomChechBox">
                                        <input type="checkbox" v-model="tradersId" :name="'checkbox'+item.providerId" :value="item.providerId" @change="$router.push({query:{'p':tradersId.join(',').toString()}});getTraderPerformanceData()" />
                                        <span class="checkmark"></span>
                                    </label>
                                </div> -->
                                <div class="d-flex align-items-center">
                                    <span class="zlds-avatar-small menuitem position-relative d-block me-2 flex-none">
                                        <v-lazy-image class="rounded-circle" :src="(!item.photoApproved) ? (item.customerAvatarUrl) ? item.customerAvatarUrl : static_vars.imagesURL+'webservices/Image.ashx?type=provider&size=XS&id='+item.providerId+'&ignore=false' : static_vars.imagesURL+'webservices/Image.ashx?type=provider&size=XS&id='+item.providerId+'&ignore=false'" :alt="item.name" :title="item.name" />
                                    </span>
                                    <div class="medium f-18 secondary"><span class="">{{item.name}}</span></div>
                                </div>
                                <div class="addComButton">
                                    <a href="javascript:void(0)" @click="AddToCompare(item)" v-if="!tradersId.includes(item.providerId.toString())"><vue-feather type="plus-circle"></vue-feather> </a>
                                    <a href="javascript:void(0)" @click="AddToCompare(item)" v-else><vue-feather type="minus"></vue-feather> </a>
                                </div>
                            </div>

                            <div class="d-flex flex-column w-100 ps-2">
                               
                                <div class="d-flex justify-content-between flex-wrap">
                                    <div class="listBlock">
                                        <div class="small f-12 inactive">{{$t('compare_leaders.following')}}</div>
                                        <span class="f-14 medium">${{Math.abs(item.amountFollowing) > 999 ? Math.sign(item.amountFollowing)*((Math.abs(item.amountFollowing)/1000).toFixed(1)) + 'k' : Math.sign(item.amountFollowing)*Math.abs(item.amountFollowing)}}</span>
                                    </div>
                                    <div class="listBlock">
                                        <div class="small f-12 inactive">{{$t('compare_leaders.weeks')}}</div>
                                        <span class="f-14 medium">{{item.weeks}}</span>
                                    </div>
                                    <div class="listBlock">
                                        <div class="small f-12 inactive">{{$t('compare_leaders.investors')}}</div>
                                        <span class="f-14 medium">{{item.followers}}</span>
                                    </div>
                                    <div class="listBlock">
                                        <div class="small f-12 inactive">{{$t('compare_leaders.roi')}}</div>
                                        <span class="f-14 medium">{{parseFloat(item.rorBasedRoi.toFixed(2)).toLocaleString()}}%</span>
                                    </div>
                                    <div class="listBlock">
                                        <div class="small f-12 inactive">{{$t('compare_leaders.ranking')}}</div>
                                        <span class="f-14 medium">#{{item.zuluRank}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer border-0 pt-0">
                    <vue-feather class="icon" type="chevron-left" v-if="page > 0" @click="page=parseInt(page)-1;getTradersList()"></vue-feather>
                    <vue-feather class="icon" type="chevron-right" @click="page=parseInt(page)+1;getTradersList()"></vue-feather>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { myStore } from "@/store/pinia-store";
// import moment from "moment";
export default {
	setup() {
		const store = myStore();
		return { store };
	},
	data() {
		return {
			tradersId : [],
			addTraders : false,
			page : 0,
            serachTrader : ''
		};
	},
	methods:{
        removeTrader(item){
            if(item.trader.providerId){
                let index = this.tradersId.indexOf(item.trader.providerId.toString());
                if(index > -1){
                    this.tradersId.splice(index,1)
                    this.getTraderPerformanceData()
                    this.$router.push({query:{'p':this.tradersId.join(',')}})
                }
            }
        },
        AddToCompare(item){
            if(item.providerId){
                let index = this.tradersId.indexOf(item.providerId.toString());
                if(index > -1){
                    this.tradersId.splice(index,1)
                    this.getTraderPerformanceData()
                    this.$router.push({query:{'p':this.tradersId.join(',')}})
                }else{
                    this.tradersId.push(item.providerId.toString());
                    this.getTraderPerformanceData()
                    this.$router.push({query:{'p':this.tradersId.join(',')}})
                }
            }
        },
		ShowTradersList(){
			if(this.addTraders){
				this.addTraders = false
			}else{
				this.addTraders = true
				this.getTradersList()
			}
		},
		getTradersList(){
			this.store.callFollowersCompareList({},false,this.page,this.serachTrader)
		},
		getTraderPerformanceData(){
            this.tradersId = this.tradersId.map(val => val.toString())
			let formData = {};
			formData['flavor'] = 'global'
			formData['includeProviderIds'] = this.tradersId
			this.store.compareTraderPerformanceData(formData,false,this)
		},
        // tradeTime(seconds){
        //     let duration = moment.duration(seconds, 'seconds')
        //     return duration.format("dd");
        // },
	},
	created(){
		if(this.$route.query.p){
			this.tradersId = this.$route.query.p.split(',')
			this.store.$patch({compareTraderPerformance:{}})
			this.getTraderPerformanceData()
		}
	}
};
</script>
